@import '../../common/css-vars.css';

.login-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  justify-content: center;
}

.login-container {
  display: flex;
  justify-content: center;
}

.social-buttons {
}

.google-button {
  background-color: var(--grey) !important;
  color: var(--white) !important;
  width: 350px;
}
