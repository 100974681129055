@import '../../common/css-vars.css';

.notfound-section {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.video {
  background-color: var(--black);
  position: fixed;
  z-index: -1;
  margin-left: 50vw;
  transform: translate(-50%);
}

.error-wrapper {
  color: var(--black);
  display: flex;
  flex-direction: column;
  margin: 15vh;
}

.title-wrapper {
  color: var(--black);
  margin: 7vh;
  text-align: center;
}

.error-code {
  display: flex;
  justify-content: center;
  color: var(--white);
  font-size: 15vh;
  animation: glitch 1s linear infinite;
  letter-spacing: -7px;
}

.error-code::before,
.error-code::after {
  content: attr(title);
  position: absolute;
}

.error-code::before {
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

.error-code::after {
  animation: glitchBottom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

.error-message {
  display: flex;
  justify-content: center;
  color: var(--white);
}

.title {
  display: flex;
  justify-content: center;
  color: var(--white);
}

@keyframes glitch {
  2%,
  64% {
    transform: translate(2px, 0) skew(0deg);
  }
  4%,
  60% {
    transform: translate(-2px, 0) skew(0deg);
  }
  62% {
    transform: translate(0, 0) skew(2deg);
  }
}

@keyframes glitchTop {
  2%,
  64% {
    transform: translate(2px, -2px);
  }
  4%,
  60% {
    transform: translate(2px, -2px);
  }
  62% {
    transform: translate(10px, -2px) skew(-10deg);
  }
}

@keyframes glitchBottom {
  2%,
  64% {
    transform: translate(-2px, 0);
  }
  4%,
  60% {
    transform: translate(-2px, 0);
  }
  62% {
    transform: translate(-10px, 2px) skew(-5deg);
  }
}

@media (min-aspect-ratio: 16/9) {
  .video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .video {
    width: auto;
    height: 100%;
  }
}
