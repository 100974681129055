@import '../../common/css-vars.css';

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 40px;
  min-width: 95px;
}

.btn:hover {
  padding: 8px 20px;
  transition: all 0.3s ease-out;
  background: var(--white);
  color: var(--secondary);
}

.btn-primary {
  background-color: var(--primary);
}

.btn-outline {
  background-color: transparent;
  color: var(--white);
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn-medium {
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 25px;
  color: var(--white);
  min-width: 150px;
}

.btn-large {
  padding: 12px 26px;
  border-radius: 4px;
  font-size: 30px;
  color: var(--white);
  min-width: 150px;
}
