@font-face {
  font-family: 'Fira Mono';
  src: local('Fira Mono'), url(./assets/fonts/FiraMono-Regular.ttf) format('ttf');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Fira Mono', monospace;
  font-size: 3vh;
  letter-spacing: -3px;
}
